<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="运单组别编号" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="车主手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车主手机" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨数" value="1"></el-option>
					<el-option label="按方数" value="2"></el-option>
					<el-option label="按趟" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回程">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="需要" value="1"></el-option>
					<el-option label="不需要" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="添加网点">
				<el-select class="el_input" v-model="form.outlets_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in ser_para.outlets_list" :key="index" :label="item.name" :value="item.outlets_num"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="运单编号/组别编号/上游单号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">(编号) {{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">(组别) {{scope.row.group1_num}}</div>
						<div class="tab_line_item">(上游) {{scope.row.out_trade_num}}</div>
					</template>
				</el-table-column>
				
				
				

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="48">
					<template slot-scope="scope">
						<el-button @click="voucher_show(scope.row)" class="btn_left" size="mini" type="text" >凭证</el-button>
						<el-button @click="open_upl_view(scope.row)" class="btn_left" size="mini" type="text">上报</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 运单修改界面 -->
		<upl 
			:is_show="upl.is_show" 
			:tord_info="upl.tord_info" 
			@close="upl_save_callback"
		></upl>

		<!-- 凭证界面 -->
		<voucher :is_show="voucher.is_show" :voucher_info="voucher.voucher_info"></voucher>
	</div>
</template>

<script>
	import upl from './upl.vue'
	import voucher from '../../../transit_real_time/voucher/voucher_after_end.vue'
	export default {
		components:{
			upl,
			voucher
		},
		data() {
			return {

				//搜索条件参数
				ser_para:{
					
					outlets_list:[],//网点列表
				},

				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					outlets_num:'',//网点编号
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:'',
					voucher_info:[],
				},

				//上报弹出层
				upl:{
					tord_info:{},
					is_show:0,
				},
			}
		},
		created() {

			//读取网点列表
			this.get_outlets_list();
			
			//读取货源
			this.get_page_data()
		},
		methods: {

			//打开上报页面
			open_upl_view(item){

				//置入货车数据
				this.upl.tord_info=item

				//打开弹出层
				this.upl.is_show++;
			},
			upl_save_callback(){//上报界面关闭回调
				
				//复位
				this.upl.is_show=0;

				//刷新页面
				this.get_page_data()
			},


			






			
			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.voucher_info=item.voucher_info
			},

			//运单删除
			tord_del(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'删除运单',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_end_del_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					outlets_num:'',//网点编号
				}
				this.get_page_data()
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					// return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'logink',
						ctr:'tord_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						// //预处理
						// for(let item of data.list){

						// 	//运单创建时间
						// 	item.tord_creat_time_text=this.$my.other.totime(item.tord_creat_time);
							
						// 	//运单发车时间
						// 	if(item.tord_start_time>0){//已发车
						// 		item.tord_start_time_text=this.$my.other.totime(item.tord_start_time);
						// 	}else item.tord_start_time_text="未发车";
							
						// 	//运单到达时间
						// 	if(item.tord_arrived_time>0){//已到货
						// 		item.tord_arrived_time_text=this.$my.other.totime(item.tord_arrived_time);
						// 	}else item.tord_arrived_time_text="未到货";

						// 	//运单结束时间
						// 	item.tord_end_time_text=this.$my.other.totime(item.tord_end_time);

						// 	//运单状态
						// 	switch(item.tord_status){
						// 		case '1':item.tord_status_text="待发车";break;
						// 		case '2':item.tord_status_text="运输中";break;
						// 		case '3':item.tord_status_text="已到货";break;
						// 	}

						// 	//结算方式
						// 	switch(item.settlement_type){
						// 		case '1':item.settlement_type_text="按吨数";break;
						// 		case '2':item.settlement_type_text="按方数";break;
						// 		case '3':item.settlement_type_text="按趟";break;
						// 	}
							
						// 	//是否回程
						// 	switch(item.is_need_return){
						// 		case '1':item.is_need_return_text="需要回程";break;
						// 		case '2':item.is_need_return_text="不需要回程";break;
						// 	}

						// 	//运费支付状态
						// 	switch(item.freight_payed_status){
						// 		case '1':item.freight_payed_status_text="未支付";break;
						// 		case '2':item.freight_payed_status_text="部分支付";break;
						// 		case '3':item.freight_payed_status_text="支付完毕";break;
						// 		default :item.freight_payed_status_text="未支付";break;
						// 	}

						// 	//货源详情
						// 	item.cargo_info=JSON.parse(item.cargo_info)

						// 	//货主详情
						// 	item.shipper_info=JSON.parse(item.shipper_info)

						// 	//网点信息
						// 	item.outlets_info=JSON.parse(item.outlets_info)

						// 	//车主详情
						// 	item.truck_owner_info=JSON.parse(item.truck_owner_info)

						// 	//司机数据
						// 	item.driver_info=JSON.parse(item.driver_info)

						// 	//货车详情
						// 	item.truck_info=JSON.parse(item.truck_info)

						// 	//费用信息
						// 	item.cost_info=JSON.parse(item.cost_info)

						// 	//凭证列表
						// 	item.voucher_info=JSON.parse(item.voucher_info)
						// }

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取网点列表
			get_outlets_list(){
				
				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_list_admin',
						num:1000
					},
					callback:(data)=>{
						
						//渲染
						this.ser_para.outlets_list=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>